import 'regenerator-runtime/runtime'

import LaserEditor from './laser-editor.js'
import vectorData from './Deer.json'
import fontData from './Font20201007.json'

const laserEditor = new LaserEditor({
  vectorData,
  fontData,
  defaultFontSize: 20,
  minFontSize: 10,
  maxFontSize: 50,
  gap: 5,
  collisionMatrixScale: 0.25,
  collisionMatrixBlur: 255,
  showCollisionMatrix: false,
  dxfGeneratorURL: 'https://laserschneiden.azurewebsites.net/get_gross_dxf',
  dxfFilename: 'Deer',
  editLabel: 'Edit',
  deleteLabel: 'Delete',
  cancelLabel: 'Cancel',
  downloadLabel: 'Download'
})

document.body.appendChild(laserEditor)
