import Typr from './Typr.js'

/******************************************************************************
 *                             C O N S T A N T S                              *
 ******************************************************************************/

const css = `

:host {
  --toolbar-height: 75px;
  position: fixed;
  top: var(--margin-top, 0);
  right: var(--margin-right, 0);
  bottom: var(--margin-bottom, 0);
  left: var(--margin-left, 0);
  background-color: var(--editor-bgcolor);
}

.menu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  opacity: 0;
  transition: opacity 0.25s;
}

.menu-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: var(--font-family);
}

.menu-content button {
  display: block;
  margin: 10px;
  width: 200px;
  height: 40px;
  color: black;
  background-color: white;
  border: 1px solid black;
  outline: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: inherit;
  font-size: 15px;
  cursor: pointer;
  transition: color 0.25s, background-color 0.25s;
}

.menu-content button:hover {
  color: white;
  background-color: black;
}

.toolbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: var(--toolbar-height);
  background-color: var(--toolbar-bgcolor);
  font-family: var(--font-family);
}

.font-size {
  position: absolute;
  left: 15px;
  width: var(--toolbar-height);
  height: var(--toolbar-height);
  border: 0;
  outline: 0;
  color: #4A5568;
  background-color: transparent;
  cursor: pointer;
  font-size: 15px;
  font-family: inherit;
  transition: color 0.25s;
}

.font-size:hover {
  color: #1A202C;
}

.generate {
  display: none;
  position: absolute;
  right: 35px;
  width: var(--toolbar-height);
  height: var(--toolbar-height);
}

.generate div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: generate 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.generate div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes generate {
  0% {
    top: calc(var(--toolbar-height) / 2 - 4px);
    left: calc(var(--toolbar-height) / 2 - 4px);
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: calc(var(--toolbar-height) - 8px);
    height: calc(var(--toolbar-height) - 8px);
    opacity: 0;
  }
}

.download {
  position: absolute;
  right: 0;
  height: var(--toolbar-height);
  padding: 0 25px;
  color: #4A5568;
  background-color: transparent;
  border: 0;
  outline: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: inherit;
  font-size: 15px;
  cursor: pointer;
  transition: color 0.25s;
}

.download:hover {
  color: #1A202C;
}

.download:disabled {
  color: #A0AEC0;
  cursor: not-allowed;
}

.editor {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: calc(100% - var(--toolbar-height));
}

.cutoff {
  cursor: text;
}

@keyframes blink {
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
}

.cursor {
  stroke: white;
  animation: blink 1s infinite;
}

.text {
  pointer-events: bounding-box;
  fill: var(--editor-bgcolor);
}

.text:hover {
  cursor: move;
}

@keyframes collision {
  0% {fill: var(--editor-bgcolor);}
  50% {fill: #F56565;}
  100% {fill: var(--editor-bgcolor);}
}

.text.collision {
  fill: #F56565;
  animation: collision 0.5s infinite;
}

`

const svgNS = 'http://www.w3.org/2000/svg'

const html = `
  <style>${css}</style>
  <div class="menu">
    <div class="menu-content">
      <button id="edit" type="button"></button>
      <button id="delete" type="button"></button>
      <button id="cancel" type="button"></button>
    </div>
  </div>
  <div class="toolbar">
    <select class="font-size"></select>
    <div class="generate"><div></div><div></div></div>
    <button class="download" type="button"></button>
  </div>
  <svg class="editor" xmlns="${svgNS}" transform="matrix(1, 0, 0, -1, 0, 0)">
    <path class="cutoff"></path>
    <line class="cursor" />
  </svg>
`

const hidden = Symbol('hidden')
const empty = Symbol('empty')
const active = Symbol('active')
const menu = Symbol('menu')
const dragged = Symbol('dragged')

const template = document.createElement('template')
template.innerHTML = html

/******************************************************************************
 *                         W E B   C O M P O N E N T                          *
 ******************************************************************************/

export default class LaserEditor extends window.HTMLElement {
  constructor (params) {
    super()
    const content = template.content.cloneNode(true)
    const ctx = context(params, content)
    $moveCursor(ctx, ctx.cursorX, ctx.cursorBaseline, ctx.cursorPosition)
    ctx.Editor.addEventListener('click', e => $editorClick(ctx, e))
    ctx.Cutoff.addEventListener('click', e => $cutoffClick(ctx, e))
    ctx.FontSize.addEventListener('change', () => $fontSizeChange(ctx))
    ctx.Download.addEventListener('click', () => $downloadClick(ctx))
    ctx.Menu.addEventListener('click', e => $menuClick(ctx, e))
    ctx.Edit.addEventListener('click', () => $editClick(ctx))
    ctx.Delete.addEventListener('click', () => $deleteClick(ctx))
    ctx.Cancel.addEventListener('click', () => $cancelClick(ctx))
    window.addEventListener('keydown', e => $keydown(ctx, e))
    window.addEventListener('mousemove', e => $mousemove(ctx, e))
    window.addEventListener('mouseup', e => $mouseup(ctx, e))
    this.connected = () => $createCollisionMatrix(ctx)
    this.attachShadow({ mode: 'open' }).appendChild(content)
  }

  connectedCallback () {
    this.connected()
    delete this.connected
  }
}

window.customElements.define('laser-editor', LaserEditor)

/******************************************************************************
 *                        I N I T I A L I Z A T I O N                         *
 ******************************************************************************/

function context (params, content) {
  return { ...constants(params), ...state(params), ...nodes(params, content) }
}

function constants (params) {
  const FONT = Typr.parse(new Uint8Array(params.fontData).buffer)[0]
  return {
    FONT,
    UNITS_PER_EM: FONT.head.unitsPerEm,
    ASCENDER: FONT['OS/2'].sTypoAscender,
    DESCENDER: FONT['OS/2'].sTypoDescender,
    GAP: params.gap,
    COLLISION_MATRIX_SCALE: params.collisionMatrixScale,
    COLLISION_MATRIX_BLUR: params.collisionMatrixBlur,
    SHOW_COLLISION_MATRIX: params.showCollisionMatrix,
    DXF_GENERATOR_URL: params.dxfGeneratorURL,
    DXF_FILENAME: params.dxfFilename
  }
}

function state (params) {
  const { vectorData: { cursor: { x, baseline } } } = params
  return {
    state: empty,
    fontSize: params.defaultFontSize,
    cursorX: x,
    cursorBaseline: baseline,
    cursorPosition: 0,
    texts: new Map(),
    dragX: null,
    dragY: null
  }
}

function nodes (params, content) {
  const FontSize = content.querySelector('.font-size')
  FontSize.appendChild(fontSizeOpts(params))
  const Generate = content.querySelector('.generate')
  const Download = content.querySelector('.download')
  Download.textContent = params.downloadLabel
  const Editor = content.querySelector('.editor')
  Editor.setAttribute('viewBox', viewBox(params.vectorData.extents))
  const Cutoff = content.querySelector('.cutoff')
  Cutoff.setAttribute('d', svgPath(params.vectorData.chains))
  const Cursor = content.querySelector('.cursor')
  const mainElements = { FontSize, Generate, Download, Editor, Cutoff, Cursor }
  const stateElements = { ActiveText: null, DraggedText: null }
  return { ...mainElements, ...stateElements, ...menuElements(params, content) }
}

function menuElements (params, content) {
  const Menu = content.querySelector('.menu')
  const RightClickedText = null
  const Edit = content.querySelector('#edit')
  Edit.textContent = params.editLabel
  const Delete = content.querySelector('#delete')
  Delete.textContent = params.deleteLabel
  const Cancel = content.querySelector('#cancel')
  Cancel.textContent = params.cancelLabel
  return { Menu, RightClickedText, Edit, Delete, Cancel }
}

/******************************************************************************
 *                        P U R E   F U N C T I O N S                         *
 ******************************************************************************/

function fontSizeOpts ({ defaultFontSize, minFontSize, maxFontSize }) {
  const options = document.createDocumentFragment()
  for (let i = minFontSize; i <= maxFontSize; i++) {
    const option = document.createElement('option')
    option.value = i
    option.textContent = `${i} mm`
    if (i === defaultFontSize) option.selected = true
    options.appendChild(option)
  }
  return options
}

function viewBox ({ xMin, yMin, xMax, yMax }) {
  return `${xMin} ${yMin} ${xMax - xMin} ${yMax - yMin}`
}

function svgPath (chains) {
  const parts = []
  for (const chain of chains) {
    parts.push('M', chain[0][1], chain[0][2])
    for (const entity of chain) {
      if (entity[0] === 'SPLINE') {
        if (entity.length === 9) parts.push('C', ...entity.slice(3))
        else if (entity.length === 7) parts.push('Q', ...entity.slice(3))
        else throw new Error('Unknown SPLINE entity')
      } else if (entity[0] === 'ARC') {
        const [u1, u2] = [entity[1] - entity[3], entity[2] - entity[4]]
        const [v1, v2] = [entity[8] - entity[3], entity[9] - entity[4]]
        const sweep = u1 * v2 - u2 * v1 < 0 ? 0 : 1
        parts.push('A', entity[5], entity[5], 0, 0, sweep, entity[8], entity[9])
      } else throw new Error('Unknown entity')
    }
    parts.push('Z')
  }
  return parts.join(' ')
}

function cursorX (shape, position, textX, fontSize, unitsPerEm) {
  let x = 0
  for (let i = 0; i < position; i++) x += shape[i].ax
  return x * fontSize / unitsPerEm + textX
}

function transformCrds (crds, fontSize, unitsPerEm, x, y) {
  const scale = fontSize / unitsPerEm
  return crds.map((c, i) => c * scale + (i % 2 === 0 ? x : y))
}

function editorCrds (ctx, { clientX, clientY }) {
  let svgPoint = ctx.Editor.createSVGPoint()
  svgPoint.x = clientX
  svgPoint.y = clientY
  svgPoint = svgPoint.matrixTransform(ctx.Cutoff.getScreenCTM().inverse())
  return [svgPoint.x, svgPoint.y]
}

function isCharSupported (font, key) {
  return key.length === 1 && Typr.U.shape(font, key, true)[0].g !== 0
}

function isBBoxCollision (svgElem1, svgElem2, gap, mx1, my1, mx2, my2) {
  let { x: x1, y: y1, width: w1, height: h1 } = svgElem1.getBBox()
  let { x: x2, y: y2, width: w2, height: h2 } = svgElem2.getBBox()
  if (w1 === 0 || h1 === 0 || w2 === 0 || h2 === 0) return false
  x1 += mx1
  y1 += my1
  x2 += mx2
  y2 += my2
  const horizontalCollision = x1 - gap < x2 + w2 && x1 + w1 + gap > x2
  const verticalCollision = y1 - gap < y2 + h2 && y1 + h1 + gap > y2
  return horizontalCollision && verticalCollision
}

function isCutoffCollision (svgElem, cutoffBBox, matrix, gap, mx, my) {
  const { x, y, width, height } = svgElem.getBBox()
  if (width === 0 || height === 0) return false
  const leftX = x + mx - gap
  const rightX = leftX + width + 2 * gap
  const bottomY = y + my - gap
  const topY = bottomY + height + 2 * gap
  const { x: coX, y: coY, width: coW, height: coH } = cutoffBBox
  const isOutOfCutoff =
    leftX < coX || rightX >= coX + coW || bottomY < coY || topY >= coY + coH
  if (isOutOfCutoff) return true
  const cellWidth = coW / matrix[0].length
  const cellHeight = coH / matrix.length
  const rowIdx = y => Math.floor((y - coY) / cellHeight)
  const colIdx = x => Math.floor((x - coX) / cellWidth)
  for (let row = rowIdx(bottomY); row <= rowIdx(topY); row++) {
    for (let col = colIdx(leftX); col <= colIdx(rightX); col++) {
      if (!matrix[row][col]) return true
    }
  }
  return false
}

function insertChar (text, newChar, position) {
  return text.slice(0, position) + newChar + text.slice(position)
}

function backspaceChar (text, position) {
  if (position === 0) return text
  return text.slice(0, position - 1) + text.slice(position)
}

function deleteChar (text, position) {
  return text.slice(0, position) + text.slice(position + 1)
}

function collisionMatrixCanvas (cutoffBBox, cutoffData, collisionMatrixScale) {
  const canvas = document.createElement('canvas')
  const { x: cutoffX, y: cutoffY, width: cutoffWidth, height: cutoffHeight } =
    cutoffBBox
  canvas.width = Math.round(cutoffWidth * collisionMatrixScale)
  canvas.height = Math.round(cutoffHeight * collisionMatrixScale)
  const canvasCtx = canvas.getContext('2d')
  canvasCtx.scale(collisionMatrixScale, collisionMatrixScale)
  canvasCtx.translate(-cutoffX, -cutoffY)
  const path2D = new window.Path2D(cutoffData)
  canvasCtx.fill(path2D)
  return canvas
}

function collisionMatrix (imageData, blur) {
  const { data, width, height } = imageData
  const matrix = []
  for (let r = 0; r < height; r++) {
    const row = []
    for (let c = 0; c < width; c++) {
      const alpha = data[r * width * 4 + c * 4 + 3]
      row.push(alpha >= blur)
    }
    matrix.push(row)
  }
  return matrix
}

function collisionMatrixImageData (matrix) {
  const data = []
  const red = [255, 0, 0, 255]
  const green = [0, 255, 0, 255]
  for (let row = matrix.length - 1; row >= 0; row--) {
    for (const col of matrix[row]) data.push(...(col ? green : red))
  }
  const canvasCtx = document.createElement('canvas').getContext('2d')
  const imageData = canvasCtx.createImageData(matrix[0].length, matrix.length)
  for (let i = 0; i < data.length; i++) imageData.data[i] = data[i]
  return imageData
}

function dxfData (svgPath) {
  const { cmds, crds } = svgPath
  const data = []
  let crdIdx = 0
  let firstCrd
  let prevCrd
  const nextCrd = () => [crds[crdIdx++], crds[crdIdx++]]
  for (const cmd of cmds) {
    if (cmd === 'M') {
      firstCrd = prevCrd = nextCrd()
    } else if (cmd === 'L') {
      data.push(['L', ...prevCrd, ...(prevCrd = nextCrd())])
    } else if (cmd === 'Q') {
      data.push(['B', ...prevCrd, ...nextCrd(), ...(prevCrd = nextCrd())])
    } else if (cmd === 'C') {
      const controlPoints = [...nextCrd(), ...nextCrd()]
      data.push(['B', ...prevCrd, ...controlPoints, ...(prevCrd = nextCrd())])
    } else if (cmd === 'Z') {
      data.push(['L', ...prevCrd, ...firstCrd])
    }
  }
  return data
}

/******************************************************************************
 *                              M U T A T O R S                               *
 ******************************************************************************/

let cursorBlinkTimeoutID

function $moveCursor (ctx, x, baseline, position) {
  ctx.cursorX = x
  ctx.cursorBaseline = baseline
  ctx.cursorPosition = position
  const { fontSize, DESCENDER, ASCENDER, UNITS_PER_EM } = ctx
  ctx.Cursor.setAttribute('x1', x)
  ctx.Cursor.setAttribute('x2', x)
  ctx.Cursor.setAttribute('y1', baseline + fontSize * DESCENDER / UNITS_PER_EM)
  ctx.Cursor.setAttribute('y2', baseline + fontSize * ASCENDER / UNITS_PER_EM)
  clearTimeout(cursorBlinkTimeoutID)
  ctx.Cursor.style.animation = 'none'
  cursorBlinkTimeoutID =
    setTimeout(() => { ctx.Cursor.style.animation = '' }, 500)
}

function $stepCursor (ctx, position) {
  const { text, x: textX, baseline, fontSize, shape } =
    ctx.texts.get(ctx.ActiveText)
  const pos = Math.min(text.length, Math.max(position, 0))
  const x = cursorX(shape, pos, textX, fontSize, ctx.UNITS_PER_EM)
  $moveCursor(ctx, x, baseline, pos)
}

function $createText (ctx, firstChar) {
  if (firstChar === ' ') return
  ctx.state = active
  const path = document.createElementNS(svgNS, 'path')
  $textMousedown(ctx, path)
  $textRightClick(ctx, path)
  const shape = Typr.U.shape(ctx.FONT, firstChar, true)
  const svgPath = Typr.U.shapeToPath(ctx.FONT, shape)
  const { fontSize, UNITS_PER_EM, cursorX: x, cursorBaseline: baseline } = ctx
  svgPath.crds =
    transformCrds(svgPath.crds, fontSize, UNITS_PER_EM, x, baseline)
  const textState = { text: firstChar, x, baseline, fontSize, shape, svgPath }
  ctx.texts.set(path, textState)
  path.setAttribute('class', 'text')
  path.setAttribute('d', Typr.U.pathToSVG(svgPath))
  ctx.Editor.appendChild(path)
  ctx.ActiveText = path
  $stepCursor(ctx, 1)
  $checkCollision(ctx)
}

function $updateText (ctx, text) {
  const shape = Typr.U.shape(ctx.FONT, text, true)
  const svgPath = Typr.U.shapeToPath(ctx.FONT, shape)
  const textState = ctx.texts.get(ctx.ActiveText)
  const { x, baseline, fontSize } = textState
  svgPath.crds =
    transformCrds(svgPath.crds, fontSize, ctx.UNITS_PER_EM, x, baseline)
  textState.text = text
  textState.shape = shape
  textState.svgPath = svgPath
  ctx.ActiveText.setAttribute('d', Typr.U.pathToSVG(svgPath))
}

function $insertCharacter (ctx, chr) {
  if (ctx.cursorPosition === 0 && chr === ' ') return
  const currentText = ctx.texts.get(ctx.ActiveText).text
  const newText = insertChar(currentText, chr, ctx.cursorPosition)
  $updateText(ctx, newText)
  $stepCursor(ctx, ctx.cursorPosition + 1)
  $checkCollision(ctx)
}

function $backspaceCharacter (ctx) {
  const { text } = ctx.texts.get(ctx.ActiveText)
  if (ctx.cursorPosition === 0) {
    $stepCursor(ctx, 0)
  } else if (text.length > 1) {
    $updateText(ctx, backspaceChar(text, ctx.cursorPosition))
    $stepCursor(ctx, ctx.cursorPosition - 1)
    $checkCollision(ctx)
  } else {
    ctx.state = empty
    $stepCursor(ctx, 0)
    ctx.texts.delete(ctx.ActiveText)
    ctx.Editor.removeChild(ctx.ActiveText)
    ctx.ActiveText = null
    $checkCollision(ctx)
  }
}

function $deleteCharacter (ctx) {
  const { text } = ctx.texts.get(ctx.ActiveText)
  if (ctx.cursorPosition === text.length) {
    $stepCursor(ctx, text.length)
  } else if (text.length > 1) {
    $updateText(ctx, deleteChar(text, ctx.cursorPosition))
    $stepCursor(ctx, ctx.cursorPosition)
    $checkCollision(ctx)
  } else {
    ctx.state = empty
    $stepCursor(ctx, 0)
    ctx.texts.delete(ctx.ActiveText)
    ctx.Editor.removeChild(ctx.ActiveText)
    ctx.ActiveText = null
    $checkCollision(ctx)
  }
}

function $closeMenu (ctx) {
  setTimeout(() => { ctx.Menu.style.zIndex = '' }, 250)
  ctx.Menu.style.opacity = ''
  ctx.RightClickedText = null
  ctx.Edit.blur()
  ctx.Delete.blur()
  ctx.Cancel.blur()
}

function $editorClick (ctx, e) {
  if (e.path[0] === ctx.Editor) {
    ctx.state = hidden
    ctx.Cursor.style.display = 'none'
    ctx.ActiveText = null
  }
}

function $cutoffClick (ctx, e) {
  ctx.state = empty
  $moveCursor(ctx, ...editorCrds(ctx, e), 0)
  ctx.Cursor.style.display = ''
  ctx.ActiveText = null
}

function $keydown (ctx, e) {
  if (isCharSupported(ctx.FONT, e.key)) {
    if (ctx.state === empty) $createText(ctx, e.key)
    else if (ctx.state === active) $insertCharacter(ctx, e.key)
  } else if (ctx.state === active) {
    const { text } = ctx.texts.get(ctx.ActiveText)
    if (e.key === 'ArrowLeft') $stepCursor(ctx, ctx.cursorPosition - 1)
    else if (e.key === 'ArrowRight') $stepCursor(ctx, ctx.cursorPosition + 1)
    else if (e.key === 'Home') $stepCursor(ctx, 0)
    else if (e.key === 'End') $stepCursor(ctx, text.length)
    else if (e.key === 'Backspace') $backspaceCharacter(ctx)
    else if (e.key === 'Delete') $deleteCharacter(ctx)
  }
}

function $fontSizeChange (ctx) {
  ctx.FontSize.blur()
  ctx.fontSize = parseInt(ctx.FontSize.value)
  if (ctx.state === empty || ctx.state === hidden) {
    $moveCursor(ctx, ctx.cursorX, ctx.cursorBaseline, ctx.cursorPosition)
  } else if (ctx.state === active) {
    const textState = ctx.texts.get(ctx.ActiveText)
    textState.fontSize = ctx.fontSize
    $updateText(ctx, textState.text)
    $stepCursor(ctx, ctx.cursorPosition)
    $checkCollision(ctx)
  }
}

function $textRightClick (ctx, path) {
  path.addEventListener('contextmenu', e => {
    e.preventDefault()
    ctx.state = menu
    ctx.RightClickedText = path
    ctx.Menu.style.zIndex = 1
    ctx.Menu.style.opacity = 0.9
    if (ctx.RightClickedText === ctx.ActiveText) {
      ctx.Edit.style.display = 'none'
    } else {
      ctx.Cursor.style.display = 'none'
      ctx.ActiveText = null
      ctx.Edit.style.display = ''
    }
  })
}

function $textMousedown (ctx, path) {
  path.addEventListener('mousedown', e => {
    ctx.state = dragged
    const [dragX, dragY] = editorCrds(ctx, e)
    ctx.dragX = dragX
    ctx.dragY = dragY
    ctx.DraggedText = path
    if (ctx.DraggedText !== ctx.ActiveText) {
      ctx.Cursor.style.display = 'none'
      ctx.ActiveText = null
    }
  })
}

function $mousemove (ctx, e) {
  if (ctx.state === dragged) {
    const [x, y] = editorCrds(ctx, e)
    const [mx, my] = [x - ctx.dragX, y - ctx.dragY]
    const translate = `translate(${mx}, ${my})`
    ctx.DraggedText.setAttribute('transform', translate)
    if (ctx.DraggedText === ctx.ActiveText) {
      ctx.Cursor.setAttribute('transform', translate)
    }
    $checkCollision(ctx, mx, my)
  }
}

function $mouseup (ctx, e) {
  if (ctx.state === dragged) {
    const textState = ctx.texts.get(ctx.DraggedText)
    const [x, y] = editorCrds(ctx, e)
    textState.x += x - ctx.dragX
    textState.baseline += y - ctx.dragY
    ctx.DraggedText.removeAttribute('transform')
    if (ctx.DraggedText === ctx.ActiveText) {
      ctx.state = active
      $updateText(ctx, textState.text)
      ctx.Cursor.removeAttribute('transform')
      $stepCursor(ctx, ctx.cursorPosition)
    } else {
      ctx.state = hidden
      ctx.ActiveText = ctx.DraggedText
      $updateText(ctx, textState.text)
      ctx.ActiveText = null
    }
    ctx.DraggedText = null
  }
}

function $menuClick (ctx, e) {
  if (e.path[0].localName !== 'button') $cancelClick(ctx)
}

function $editClick (ctx) {
  ctx.state = active
  const textState = ctx.texts.get(ctx.RightClickedText)
  ctx.fontSize = textState.fontSize
  ctx.FontSize.value = textState.fontSize
  ctx.ActiveText = ctx.RightClickedText
  ctx.Cursor.style.display = ''
  $stepCursor(ctx, textState.text.length)
  $closeMenu(ctx)
}

function $deleteClick (ctx) {
  ctx.state = hidden
  ctx.texts.delete(ctx.RightClickedText)
  ctx.Editor.removeChild(ctx.RightClickedText)
  ctx.Cursor.style.display = 'none'
  ctx.ActiveText = null
  $closeMenu(ctx)
  $checkCollision(ctx)
}

function $cancelClick (ctx) {
  ctx.state = ctx.ActiveText === ctx.RightClickedText ? active : hidden
  $closeMenu(ctx)
}

function $checkCollision (ctx, draggedMx, draggedMy) {
  const isColliding = [...Array(ctx.texts.size)].map(() => false)
  const texts = Array.from(ctx.texts.keys())
  const m = x => ctx.DraggedText === texts[x] ? [draggedMx, draggedMy] : [0, 0]
  for (let i = 0; i < texts.length - 1; i++) {
    for (let j = i + 1; j < texts.length; j++) {
      if (isBBoxCollision(texts[i], texts[j], ctx.GAP, ...m(i), ...m(j))) {
        isColliding[i] = true
        isColliding[j] = true
      }
    }
  }
  const mat = ctx.COLLISION_MATRIX
  for (let i = 0; i < texts.length; i++) {
    if (isCutoffCollision(texts[i], ctx.CUTOFF_BBOX, mat, ctx.GAP, ...m(i))) {
      isColliding[i] = true
    }
  }
  for (let i = 0; i < texts.length; i++) {
    texts[i].classList[isColliding[i] ? 'add' : 'remove']('collision')
  }
  $checkDownloadState(ctx, isColliding)
}

function $checkDownloadState (ctx, isColliding) {
  for (const isColl of isColliding) {
    if (isColl) {
      ctx.Download.disabled = true
      return
    }
  }
  ctx.Download.disabled = false
}

function $createCollisionMatrix (ctx) {
  ctx.CUTOFF_BBOX = ctx.Cutoff.getBBox()
  const canvas = collisionMatrixCanvas(ctx.CUTOFF_BBOX,
    ctx.Cutoff.getAttribute('d'), ctx.COLLISION_MATRIX_SCALE)
  const canvasCtx = canvas.getContext('2d')
  const imageData = canvasCtx.getImageData(0, 0, canvas.width, canvas.height)
  const matrix = collisionMatrix(imageData, ctx.COLLISION_MATRIX_BLUR)
  ctx.COLLISION_MATRIX = matrix
  if (ctx.SHOW_COLLISION_MATRIX) {
    canvas.style = 'position: fixed; right: 0; bottom: 0;'
    canvasCtx.putImageData(collisionMatrixImageData(matrix), 0, 0)
    document.body.appendChild(canvas)
  }
}

async function $downloadClick (ctx) {
  ctx.Download.disabled = true
  ctx.Generate.style.display = 'block'
  const data = []
  for (const { svgPath } of ctx.texts.values()) data.push(...dxfData(svgPath))
  const response = await window.fetch(ctx.DXF_GENERATOR_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ filename: ctx.DXF_FILENAME, data })
  })
  const dxf = await response.text()
  const a = document.createElement('a')
  const blob = new window.Blob([dxf], { type: 'application/dxf' })
  a.setAttribute('href', window.URL.createObjectURL(blob))
  a.setAttribute('download', `${ctx.DXF_FILENAME}.dxf`)
  a.dataset.downloadurl = ['application/dxf', a.download, a.href].join(':')
  a.click()
  ctx.Generate.style.display = ''
  ctx.Download.disabled = false
}
